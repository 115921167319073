/* .loader */

.loader {
    height: 40px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    width: 40px;
    z-index: 1999;
    margin: -20px 0 0 -20px;
}
.loader .loader-load {
    background: #000 url(loader.gif) no-repeat 50% 50%;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    left: 50%;
    -webkit-opacity: 0.8;
    -khtml-opacity: 0.8;
    -moz-opacity: 0.8;
    -ms-opacity: 0.8;
    -o-opacity: 0.8;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    opacity: 0.8;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 40px;
    z-index: 1000;
    margin: -20px 0 0 -20px;
}
.loader .loader-overlay {
    background: #000;
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    -webkit-opacity: 0.6;
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-opacity: 0.6;
    -o-opacity: 0.6;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.loader.hide {
    display: none!important;
}
.loader.overlay {
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    top: 0;
    position: fixed;
}
.loader.overlay .loader-overlay {
    display: block;
}